import '@glidejs/glide/dist/css/glide.core.min.css'
import '@glidejs/glide/dist/css/glide.theme.min.css'
import '@fortawesome/fontawesome-free/js/all'

import '../css/contactless.scss'

import Alpine         from 'alpinejs'
import Glide          from '@glidejs/glide'
import SignaturePad   from 'signature_pad'
import smartcrop      from 'smartcrop'
import YTPlayer       from 'yt-player'

window.data = function() {
  return {
    visit: {},
    controls: document.getElementById("controls"),
    glide:  new Glide(".glider", {
                  dragThreshold: false,
                  gap: 0,
                  keyboard: false,
                  perView: 1,
                  rewind: false,
                  startAt: 0,
                  swipeThreshold: false,
                }),
    length: document.getElementById("fields").children.length - 1,
    fields: document.getElementById("fields").children,
    form: document.getElementById("contactless_visit"),
    next: document.getElementById("next"),
    prev: document.getElementById("prev"),
    signatureField: document.getElementById("signature"),
    transitioning: true,
    enableButtonEvent: new Event("enable"),
    disableButtonEvent: new Event("disable"),

    init: function() {
      this.disableFields()

      this.glide.mount().disable()

      this.next.addEventListener("click", this.nextField.bind(this))
      this.next.addEventListener("enable", function() {
        this.classList.remove("bg-gray-400", "text-gray-200", "opacity-50", "cursor-not-allowed")
        this.classList.add("bg-blue-500", "text-white")
      })
      this.next.addEventListener("disable", function() {
        this.classList.remove("bg-blue-500", "text-white")
        this.classList.add("bg-gray-400", "text-gray-200", "opacity-50", "cursor-not-allowed")
      })

      this.prev.addEventListener("click", this.prevField.bind(this))
      this.prev.addEventListener("enable", function() {
        this.classList.remove("bg-gray-400", "text-gray-200", "opacity-50", "cursor-not-allowed")
        this.classList.add("bg-blue-500", "text-white")
      })
      this.prev.addEventListener("disable", function() {
        this.classList.remove("bg-blue-500", "text-white")
        this.classList.add("bg-gray-400", "text-gray-200", "opacity-50", "cursor-not-allowed")
      })

      document.getElementById("fields").addEventListener("transitionstart", this.transitionStart.bind(this))
      document.getElementById("fields").addEventListener("transitionend",   this.transitionEnd.bind(this))
      this.transitionEnd()
    },

    transitionStart: function(event) {
      if (event.target.id !== "fields") {
        return
      }

      this.transitioning = true
      this.removeInputEvents()
    },

    transitionEnd: function(event) {
      if (!this.transitioning || (event !== undefined && event.target.id !== "fields")) {
        return
      }

      if (this.length > this.glide.index) {
        this.prepareField();
        this.transitioning = false
      } else {
        this.transitioning = false
        this.create()
      }
        window.dispatchEvent(new Event('resize'))
    },

    prepareField: function() {
      this.next.dispatchEvent(this.disableButtonEvent)

      var elements  = Array.from(this.currentField().querySelectorAll("input, select, checkbox, textarea"))
      this.enableFields(elements)
      this.input    = elements.pop()

      this.addInputEvents()

      this.transitioning = false

      if (this.glide.index > 0) {
        this.prev.dispatchEvent(this.enableButtonEvent)
      } else {
        this.prev.dispatchEvent(this.disableButtonEvent)
      }
    },

    create: function() {
      this.enableFields()
      this.controls.classList.add("hidden")
      this.form.submit()
    },

    addInputEvents: function() {
      if (this.input !== undefined) {
        if (this.input.type === "hidden") {
          this.setupAgreement()
        } else if (this.input.type === "file") {
          this.setupFile()
        } else if (this.input.type === "radio") {
          this.setupBoolean()
        } else {
          this.setupInput()
        }
      }
    },

    removeInputEvents: function() {
      if (this.input !== undefined) {
        if (this.input.type === "hidden") {
          this.teardownAgreement()
        } else if (this.input.type === "file") {
          this.teardownFile()
        } else if (this.input.type === "radio") {
          this.teardownBoolean()
        } else {
          this.teardownInput()
        }
      }
    },

    disableFields: function() {
      document.querySelectorAll("input, select, checkbox, textarea").forEach(function(element) {
        if (element.getAttribute("type") !== "submit") {
          element.setAttribute("disabled", "disabled")
        }
      })
    },

    enableFields: function(elements) {
      if (!elements) {
        var elements = document.querySelectorAll("input, select, checkbox, textarea")
      }

      elements.forEach(function(element) {
        element.removeAttribute("disabled")
      })
    },

    inputBlur: function(event) {
      event.preventDefault()
    },

    inputChange: function(event) {
      if (this.input.checkValidity()) {
        this.next.dispatchEvent(this.enableButtonEvent)
      } else {
        this.next.dispatchEvent(this.disableButtonEvent)
      }
    },

    fileChange: function(event) {
      if (this.input.checkValidity()) {
        const file    = this.input.files[0]
        const reader  = new FileReader()

        reader.onload = this.loadImage.bind(this)

        if (file) {
          reader.readAsDataURL(file)
        }
      }
    },

    loadImage: function(event) {
      this.image        = new Image();
      this.image.onload = this.cropImage.bind(this)
      this.image.src    = event.target.result
    },

    cropImage: function() {
      if (!this.image) return

      var preview = this.currentField().querySelector("img")

      var options = {
              height: parseInt(preview.dataset.height),
               width: parseInt(preview.dataset.width),
        ruleOfThirds: false
      }

      smartcrop.crop(this.image, options, this.drawImage.bind(this, options))
    },

    drawImage: function(options, result) {
      const field     = Array.from(this.currentField().querySelectorAll("input[type=hidden]")).pop()
      const prompt    = this.currentField().querySelector("span.prompt")

        var preview   = this.currentField().querySelector("img")


      if (isNaN(options.height) || isNaN(options.width)) {
        preview.src = this.image.src
      } else {
        const crop      = result.topCrop
          var canvas    = document.createElement("canvas")
          var context   = canvas.getContext("2d")

        canvas.height   = options.height
        canvas.width    = options.width

        context.drawImage(
          this.image,
          crop.x, crop.y, crop.width, crop.height,  // source region
          0, 0, options.width, options.height       // destination region
        )

        preview.src = canvas.toDataURL()
      }

      field.value = preview.src
      prompt.classList.add("hidden")
      preview.classList.remove("hidden")

      this.next.dispatchEvent(this.enableButtonEvent)
    },

    focusOnInput: function() {
      if (this.input !== undefined) {
        this.input.focus()
        this.input.blur()
        this.input.focus()
      }
    },

    setupAgreement: function() {
      var agreement = this.currentField().querySelector(".agreement")

      if (agreement) {
        this.input.value = this.input.dataset.id
        this.controls.classList.add("hidden")
        agreement.classList.add("agreement-auto")
        var canvas = this.currentField().querySelector("canvas")
        var video = this.currentField().querySelector("[data-video-id]")
        var videoControls = this.currentField().querySelector(".controls")

        if (canvas) {
          canvas.height = canvas.offsetHeight;
          canvas.width = canvas.offsetWidth;
          this.signature  = new SignaturePad(
                              canvas,
                              {
                                onEnd: this.signatureEnd.bind(this),
                              }
                            )
        } else {
          this.currentField().querySelector(".next").removeAttribute("disabled")
        }

        if (video) {
          videoControls.classList.add("hidden")
          this.player = new YTPlayer(
            video,
            {
              height: "100%",
              width: "100%",
              controls: false,
              keyboard: false,
              fullscreen: false,
              modestBranding: true,
              related: false,
              playsInline: false
            }
          )
          this.player.load(video.dataset.videoId)
          this.player
            .on("unstarted", () => {
              this.player.play();
            })
            .on("playing", () => {
              videoControls.classList.add("hidden")
            })
            .on("paused", () => {
              this.player.play()
            })
            .on("ended", () => {
              videoControls.classList.remove("hidden")
            })
        }
      }
    },

    teardownAgreement: function() {
      var field     = this.fields[this.glide.index - 1]

      if (field) {
        var agreement = field.querySelector(".agreement")
      }

      if (agreement) {
        agreement.classList.remove("agreement-auto")
      }
    },

    booleanChange: function(event) {
      var input = document.getElementById(event.target.dataset.id)

      if (input) {
        input.value = event.target.value
      }

      this.nextField(event)
    },

    setupBoolean: function() {
      var controller = this

      if (!controller.booleanChangeEvent) {
        controller.booleanChangeEvent = controller.booleanChange.bind(controller)
      }

      this.currentField().querySelectorAll("input[type=radio]").forEach(function(input) {
        if (input.checked) {
          input.addEventListener("click", controller.booleanChangeEvent)
        } else {
          input.addEventListener("change", controller.booleanChangeEvent)
        }
      })
    },

    teardownBoolean: function() {
      var controller = this

      this.currentField().querySelectorAll("input[type=radio]").forEach(function(input) {
        input.removeEventListener("change", controller.booleanChangeEvent)
        input.removeEventListener("click",  controller.booleanChangeEvent)
      })
    },

    setupFile: function() {
      if (!this.fileChangeEvent) {
        this.fileChangeEvent = this.fileChange.bind(this)
      }
      this.input.addEventListener("blur",   this.inputBlur)
      this.input.addEventListener("change", this.fileChangeEvent)
    },

    teardownFile: function() {
      this.input.addEventListener("blur",   this.inputBlur)
      this.input.addEventListener("change", this.fileChangeEvent)
    },

    setupInput: function() {
      if (!this.inputChangeEvent) {
        this.inputChangeEvent = this.inputChange.bind(this)
      }

      this.input.addEventListener("blur",   this.inputBlur)
      this.input.addEventListener("input",  this.inputChangeEvent)
      this.input.dispatchEvent(new Event("input"))
      this.focusOnInput()
    },

    teardownInput: function() {
      this.input.removeEventListener("blur", this.inputBlur)
      this.input.removeEventListener("input",  this.inputChangeEvent)
      this.input.setAttribute("disabled", "disabled")
    },

    signatureClear: function() {
      this.signature.clear()
      this.currentField().querySelector(".clear").classList.add("invisible")
      this.currentField().querySelector(".next").setAttribute("disabled", "disabled")
      this.signatureField.setAttribute("value", "")
    },

    signatureEnd: function() {
      if (!this.signature.isEmpty()) {
        this.currentField().querySelector(".clear").classList.remove("invisible")
        this.currentField().querySelector(".next").removeAttribute("disabled")
        this.signatureField.setAttribute("value", this.signature.toDataURL())
      }
    },

    currentField: function() {
      return this.fields[this.glide.index]
    },

    _next: function() {
      this._go(">")
    },

    _prev: function() {
      this._go("<")
    },

    _go: function(direction) {
      this.transitioning = true
      this.glide.enable().go(direction).disable()
    },

    nextField: function(event) {
      event.preventDefault()

      if (this.transitioning) {
        return
      }

      if (this.input.checkValidity()) {
        this._next()
      } else if (this.input.type !== "radio" && this.input.type !== "checkbox") {
        this.input.reportValidity()
        this.focusOnInput()
      }
    },

    prevField: function(event) {
      event.preventDefault()

      if (this.transitioning) {
        return
      }

      if (this.glide.index > 0) {
        this._prev()
      } else {
        this.focusOnInput()
      }
    },

    skipAgreement: function(event) {
      this.input.value = ""
      this.nextField(event)
    },
  }
}
